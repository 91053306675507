#home-div {
    background-image: url('../../images/landing_cover.svg');
    background-size: 100% 100%;
    height: 75vh;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;



}

.font {

    font-size: 45px;
    font-weight: bold;
    position: relative;
    color: white;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);


}





/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    #home-div {
        background-image: url('../../images/landing_cover.svg');
        background-size: 100% 100%;
        height: 75vh;

        /* Center and scale the image nicely */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .font {
        font-size: 15px;
        font-weight: bold;
        position: relative;
        color: white;
        top: 45%;
        transform: translate(-50%, -50%);
    }
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    #home-div {
        background-image: url('../../images/landing_cover.svg');
        background-size: 100% 100%;
        height: 75vh;

        /* Center and scale the image nicely */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

    }

    .font {
        font-size: 20px;
        font-weight: bold;
        position: relative;
        color: white;
        top: 45%;
        transform: translate(-50%, -50%);
    }

}


/* Medium devices (landscape tablets, 768px and up)  */
@media only screen and (min-width: 768px) {
    #home-div {
        background-image: url('../../images/landing_cover.svg');
        background-size: 100% 100%;
        height: 75vh;

        /* Center and scale the image nicely */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .font {
        font-size: 20px;
        font-weight: bold;
        position: relative;
        color: white;
        top: 45%;
        padding: 50px;
        transform: translate(-50%, -50%);
    }


}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    #home-div {
        background-image: url('../../images/landing_cover.svg');
        background-size: 100% 100%;
        height: 75vh;

        /* Center and scale the image nicely */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .font {
        font-size: 45px;
        font-weight: bold;
        position: relative;
        color: white;
        top: 45%;
        transform: translate(-50%, -50%);
    }


}


/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    #home-div {
        background-image: url('../../images/landing_cover.svg');
        height: 75vh;

        /* Center and scale the image nicely */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .font {

        font-size: 45px;
        font-weight: bold;
        position: relative;
        color: white;
        top: 45%;
        transform: translate(-50%, -50%);

    }
}



/* From https://css.glass */
/* background: rgba(86, 108, 219, 0.2);
        border-radius: 5px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(86, 108, 219, 0.3);
        padding-left: 10px;
        padding-right: 10px; */