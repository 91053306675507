.shared {
    width: 570px;
    height: 500px
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .shared {
        width: 200px;
        height: 200px
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .shared {
        width: 200px;
        height: 200px
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .shared {
        width: 200px;
        height: 200px
    }
}