.logo-title {
  /* font-size: 40px; */
}

.header {
  font-size: 15px;
  font-weight: bold;
}

.header:hover {
  border-radius: 4px;
  transition-duration: 0.4s;
  border-bottom: 4px solid #0d6efd;
}

@media screen and (max-width: 995px) {
  .submit-area {
    margin-top: 25px;
  }
}
